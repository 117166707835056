import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from '../../styles/modules/about-intro.module.scss';

const Intro = () => {
    return (
        <div className={styles.intro}>
            <div className="copy-width">
                <h1>About Us</h1>
                <div className={styles.contentContainer}>
                    <StaticImage className={styles.image} layout="fullWidth" alt="" src="../../../static/assets/homeshow.jpg" placeholder="blurred" />
                    <div>
                        <p>At Alouf Custom Builders, we have an amazing team. Mark and Chris Alouf are third-generation home builders with over 50 years of combined experience. Their wives, Crystal and Hollie, bring an extensive background of office and business management to the table.</p>
                        <p>Over the years, Alouf Custom Builders has build some amazing custom homes with timeless designs across all price points. We enjoy seeing your vision turn into your custom dream home or project. Whether it's a simple design or a million-dollar mansion, we will help you design and build something that fits your wishes, your needs <strong>and</strong> your budget!</p>
                        <p>Our commitment to quality is steadfast and our formula is simple. With every home we build, we provide the same level of care and attention to detail as if it were our own. On the other hand, we recognize that this is your home, and you will find our team to be flexible and professional. We strive to provide you with the best available options to maximize your investment in your project. We approach the building process as a partnership, working with our homeowners towards a common goal.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Intro;
