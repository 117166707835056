import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from '../../styles/modules/about-people.module.scss';

const People = () => {
    return (
        <div className={styles.people}>
            <div className="copy-width">
                <h2>Our People</h2>
                <div className={styles.contentItem}>
                    <StaticImage className={styles.image} layout="fullWidth" alt="" src="../../../static/assets/mark.png" placeholder="blurred" />
                    <div className={styles.info}>
                        <h3>Mark Alouf</h3>
                        <p>Over the years, Mark has worked on well over a hundred jobs throughout the Roanoke Valley. As a citizen of this area, he takes pride in doing his part to improve the homes and lives of his friends and neighbors. When he's not working, Mark enjoys riding the trails of West Virginia on his ATVs.</p>
                    </div>
                </div>
                <div className={styles.contentItem}>
                    <StaticImage className={styles.image} layout="fullWidth" alt="" src="../../../static/assets/chris.jpg" placeholder="blurred" />
                    <div className={styles.info}>
                        <h3>Chris Alouf</h3>
                        <p>Just like his brother, Chris has a passion for building and an incredible imagination for design. He's a fan of motorcross and enjoys riding both side-by-sides and horses.</p>
                    </div>
                </div>
                <div className={styles.contentItem}>
                    <StaticImage className={styles.image} layout="fullWidth" alt="" src="../../../static/assets/crystal.png" placeholder="blurred" />
                    <div className={styles.info}>
                        <h3>Crystal Alouf</h3>
                        <p>Having been a part of Alouf Custom Builders since it opened in 2007, Crystal performs many functions within the company. Her favorite is working with clients to re-imagine an old space or design the kitchen or bathroom of their dreams. In her free time, she loves spending time with her husband, Mark, or her two children, Taylor and John; she also loves snuggling her two fur babies.</p>
                    </div>
                </div>
                <div className={styles.contentItem}>
                    <StaticImage className={styles.image} layout="fullWidth" alt="" src="../../../static/assets/hollie.png" placeholder="blurred" />
                    <div className={styles.info}>
                        <h3>Hollie Alouf</h3>
                        <p>Hollie handles the day-to-day operations at Alouf Custom Builders, ensuring the company continues to operate smoothly. When she is not at work, she enjoys taking care of her many animals, consisting of dogs, cats, chickens, and horses.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default People;
