import React from 'react';

// Components
import Layout from '../components/layout';
import Intro from '../components/about/intro';
import People from '../components/about/people';

import routes from '../constants/routes';

const AboutPage = () => {
    return (
        <Layout slug={routes.about} title="About Us">
            <Intro />
            <People />
        </Layout>
    );
};

export default AboutPage;
